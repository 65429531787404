import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Spin } from 'antd';
import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ResourceNotFoundError } from '../models/error';
import { useAdminLoginMutation } from '../repositories/adminUser/adminUser.generated';
import { useCachedAdminMeQuery } from '../repositories/adminUser/adminUserHooks';
import { ROUTE } from '../router';
import { setAuthToken } from '../services/AuthService';
import { globalErrorHandler } from '../services/ErrorGlobalHandler';

type LoginInput = { email: string; password: string };

const LoginPage: NextPage = () => {
  const router = useRouter();
  const { data, refetch } = useCachedAdminMeQuery();
  const { mutateAsync } = useAdminLoginMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data?.adminMe) {
      router.replace('/');
    }
  }, [data]);

  const login = async (loginInput: LoginInput) => {
    try {
      setIsLoading(true);

      const email = `${loginInput.email}@wired.company`;
      const { adminLogin } = await mutateAsync({
        adminLoginInput: {
          email,
          password: loginInput.password,
        },
      });

      if (!adminLogin.accessToken) {
        throw new ResourceNotFoundError(
          'INAVLID_ACCESS_TOKEN',
          `invalid accessToken ${adminLogin.accessToken}`
        );
      }

      setAuthToken(adminLogin.accessToken);
      await refetch();
      router.push(ROUTE.home);
    } catch (e) {
      globalErrorHandler(e, loginInput);
      message.error(
        '[로그인 실패] 이메일, 비밀번호가 틀리거나 네트워크 오류입니다.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin size={'large'} spinning={isLoading}>
        <Form<LoginInput> onFinish={login}>
          <Form.Item
            name={'email'}
            rules={[
              {
                required: true,
                message: '이메일을 입력해주세요',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder={'이메일'}
              suffix={'@wired.company'}
            />
          </Form.Item>
          <Form.Item
            name={'password'}
            rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={'비밀번호'}
            />
          </Form.Item>
          <Form.Item>
            <Button type={'primary'} htmlType={'submit'} block>
              {'로그인하기'}
            </Button>
          </Form.Item>
          <div>
            <Link href={'/join'}>
              <a>{'회원가입'}</a>
            </Link>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default LoginPage;
